import React from 'react';
import { Tabs, Tab, makeStyles, Theme } from '@material-ui/core';
import { ArrowNavigationTabsBarProps } from './ArrowNavigationTabsBar.proptype';

const useStyles = makeStyles((theme: Theme) => {
  const sepColor = theme.palette.divider;
  return {
    tabs: {
      borderBottom: 0,
      backgroundColor: theme.palette.common.white,
      '& .MuiTabs-flexContainer': {
        position: 'relative',
        display: 'inline-flex',
        gap: 3,
        backgroundColor: sepColor,
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          left: '100%',
          top: 0,
          bottom: 0,
          marginLeft: '-1.8rem',
          border: '1.5rem solid transparent',
          borderLeftColor: sepColor,
          backgroundColor: theme.palette.common.white
        }
      },
      '& .MuiTabs-indicator': {
        height: 0
      },
      '& .MuiTab-root': {
        position: 'relative',
        overflow: 'visible',
        marginRight: '2rem',
        backgroundColor: theme.palette.common.white,
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          left: '100%',
          top: 0,
          bottom: 0,
          border: '1.5rem solid transparent',
          borderLeftColor: theme.palette.common.white,
          zIndex: 1
        },
        '&:not(:first-child)': {
          marginLeft: '-2rem',
          '& .MuiTab-wrapper': {
            margin: '0 -0.5rem 0 0.5rem'
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            border: '1.5rem solid transparent',
            borderLeftColor: sepColor
          }
        }
      }
    }
  };
});

const ArrowNavigationTabsBar = ({ tabs, selectedTab }: ArrowNavigationTabsBarProps) => {
  const styles = useStyles();

  return (
    <Tabs className={styles.tabs} indicatorColor="primary" textColor="primary" value={selectedTab}>
      {tabs?.map(({ key, label, disabled, onTabClick }) => (
        <Tab
          key={key}
          label={label}
          value={key}
          disabled={disabled}
          onClick={() => {
            onTabClick(key);
          }}
        />
      ))}
    </Tabs>
  );
};

export default ArrowNavigationTabsBar;
