import React from 'react';
import { makeStyles, Theme, Typography, Icon, Link, ButtonBase } from '@material-ui/core';

import Logo from '../../assets/LogoSvg';
import { ProductBadgeProps } from './ProductBadge.proptype';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex'
  },
  logoWrapper: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    marginLeft: 0,
    paddingLeft: 0,
    paddingBottom: theme.spacing(1)
  },
  logo: {
    height: '1.2rem',
    width: '1.2rem',
    fill: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1)
  },
  titleText: {
    color: theme.palette.primary.contrastText,
    fontSize: 15,
    fontWeight: 550,
    flexGrow: 1
  }
}));

const ProductBadge = ({ title, href, onClick }: ProductBadgeProps) => {
  const classes = useStyles();

  return (
    <Link href={href} className={classes.link} underline="none" onClick={onClick}>
      <ButtonBase className={classes.logoWrapper}>
        <Logo className={classes.logo} />
        <Typography className={classes.titleText}>{title}</Typography>
      </ButtonBase>
    </Link>
  );
};

export default ProductBadge;
