import React from 'react';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import Toggle from './Toggle';
import { ToggleButtonProps, ToggleButtonStyleProps } from './ToggleButton.proptype';

const useStyles = makeStyles<Theme, ToggleButtonStyleProps>((theme) => ({
  toggleSwitch: {
    margin: 'auto',
    marginLeft: theme.spacing(0.8),
    marginRight: theme.spacing(0.8)
  },
  container: {
    display: 'inline-flex',
    padding: theme.spacing(0.2),
    borderRadius: '2rem',
    backgroundColor: ({ hasBackground }) => (hasBackground ? theme.palette.secondary.light : 'transparent'),
    border: ({ hasBorder }) => (hasBorder ? `1px solid ${theme.palette.primary.main}` : 0)
  },
  captionLeft: {
    marginLeft: theme.spacing(1.6),
    fontSize: '0.9rem',
    color: theme.palette.text.secondary
  },
  captionRight: {
    marginRight: theme.spacing(1.6),
    fontSize: '0.9rem',
    color: theme.palette.text.secondary
  }
}));

const ToggleButton = ({
  handleChange,
  checked,
  leftCaption,
  rightCaption,
  hasBackground = true,
  hasBorder = false
}: ToggleButtonProps) => {
  const styles = useStyles({ hasBackground, hasBorder });

  return (
    <div className={styles.container}>
      {leftCaption && (
        <Typography variant="caption" className={styles.captionLeft}>
          {leftCaption}
        </Typography>
      )}
      <Toggle className={styles.toggleSwitch} checked={checked} onChange={handleChange} />
      {rightCaption && (
        <Typography variant="caption" className={styles.captionRight}>
          {rightCaption}
        </Typography>
      )}
    </div>
  );
};

export default ToggleButton;
