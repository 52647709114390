import React from 'react';
import classNames from 'classnames';

import { DotProgressionProps } from './DotProgression.proptype';
import { useStyles } from './DotProgression.styles';

const DotProgression = ({ dots = [] }: DotProgressionProps) => {
  const styles = useStyles();

  return (
    <>
      {dots.map((val, i) => {
        const classes = classNames({
          [styles.circle]: true,
          [styles.filled]: val
        });
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={i}>
            <div data-testid={`dot-${i}`} className={classes} />
            {i !== dots.length - 1 && <div className={styles.line} />}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default DotProgression;
